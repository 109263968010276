<template>
  <Container>
    <Headline
      :id="domId"
      :level="level"
      :size="size"
      :no-margin="!margin"
      :alignment="alignment"
      :subline="subline"
      :subline-color="sublineColor"
      :subline-font="sublineFont"
      class="alchemy-headline"
    >
      {{ text }}
    </Headline>
    <div
      v-if="links.length > 0"
      :class="['link-grid', `link-grid-alignment-${alignment}`]"
    >
      <InternalLink
        v-for="link in links"
        :key="link.id"
        :to="link.ingredients[0].linkUrl"
      >
        {{ link.ingredients[0].value }}
      </InternalLink>
    </div>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.element.ingredients.find((i) => i.role === "headline")
      },
      domId() {
        return this.headline.domId
      },
      text() {
        return this.headline.value
      },
      margin() {
        return this.getValue("margin")
      },
      size() {
        return this.headline?.size
      },
      level() {
        return this.headline?.level
      },
      alignment() {
        return this.getValue("alignment")
      },
      sublineColor() {
        return this.getValue("subline_color")
      },
      sublineFont() {
        return this.getValue("subline_font")
      },
      subline() {
        return this.getValue("subline")
      },
      links() {
        return this.element.nestedElements
      },
    },
  }
</script>

<style lang="scss" scoped>
  .alchemy-headline {
    margin-bottom: $space-m;
  }
  .link-grid {
    padding-top: $base-spacing * 4;
    display: flex;
    justify-content: center;
    grid-gap: $base-spacing * 8;
    &.link-grid-alignment-left {
      justify-content: start;
    }
    &.link-grid-alignment-right {
      justify-content: flex-end;
    }
  }
</style>
